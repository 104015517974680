'use client';

import { ThemeProvider } from 'next-themes';

import { NextThemesProviderProps } from '@/providers/NextThemesProvider/NextThemesProvider.types';

export function NextThemesProvider({
	children,
	...rest
}: NextThemesProviderProps) {
	return <ThemeProvider {...rest}>{children}</ThemeProvider>;
}
