'use client';

// ^ this file needs the "use client" pragma
import { ApolloLink, HttpLink } from '@apollo/client';
import {
    ApolloClient,
    ApolloNextAppProvider as ApolloProvider,
    InMemoryCache,
    SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support';
import {ApolloNextAppProviderProps} from "@/providers/ApolloNextAppProvider/ApolloNextAppProvider.types";

export function makeClient() {
    const httpLink = new HttpLink({
        // this needs to be an absolute url, as relative urls cannot be used in SSR
        uri: 'http://localhost:3000/api/graphql',
        // you can disable result caching here if you want to
        // (this does not work if you are rendering your page with `export const dynamic = "force-static"`)
        fetchOptions: { cache: 'no-store' },
        // you can override the default `fetchOptions` on a per query basis
        // via the `context` property on the options passed as a second argument
        // to an Apollo Client data fetching hook, e.g.:
        // const { data } = useSuspenseQuery(MY_QUERY, { context: { fetchOptions: { cache: "force-cache" }}});
    });

    return new ApolloClient({
        cache: new InMemoryCache(),
        link:
            typeof window === 'undefined' ?
                ApolloLink.from([
                    new SSRMultipartLink({
                        stripDefer: true,
                    }),
                    httpLink,
                ])
                :	httpLink,
    });
}

export function ApolloNextAppProvider({ children }: ApolloNextAppProviderProps) {
    return <ApolloProvider makeClient={makeClient}>{children}</ApolloProvider>;
}
