import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloNextAppProvider"] */ "/opt/build/repo/src/providers/ApolloNextAppProvider/ApolloNextAppProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextThemesProvider"] */ "/opt/build/repo/src/providers/NextThemesProvider/NextThemesProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/opt/build/repo/src/providers/QueryClientProvider/QueryClientProvider.tsx");
